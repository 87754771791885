import React from 'react';
import { graphql } from 'gatsby';
import MaxWidth from '../components/MaxWidth/MaxWidth';
import AboutContent from '../components/AboutContent/AboutContent';
import Meta from '../components/Meta/Meta';

export default function About({ data: { sanityAbout } }) {
  const aboutText = sanityAbout.about_text;
  const aboutImage = sanityAbout.about_image;

  return (
    <MaxWidth>
      <Meta title="About | Ottone Photography" />
      <AboutContent aboutText={aboutText} aboutImage={aboutImage} />
    </MaxWidth>
  );
}

export const query = graphql`
  query {
    sanityAbout {
      about_text
      about_image {
        alt
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
