import React from 'react';
import Img from 'gatsby-image';
import * as style from './AboutContent.module.scss';

export default function AboutContent({ aboutText, aboutImage }) {
  const image = aboutImage.image.asset.fluid;
  const { alt } = aboutImage;

  return (
    <div className={style.aboutContent}>
      <div className={style.text}>
        <h1>About</h1>
        <p>{aboutText}</p>
      </div>
      <div className={style.image}>
        <Img style={{ maxWidth: '800px', margin: 'auto' }} fluid={image} alt={alt} />
      </div>
    </div>
  );
}
